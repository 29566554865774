const environments = {
    appURL: "http://test-app.invoicecash.com/$locale/account",
    apiURL: "http://test-api.invoicecash.com",
    defaultLanguage: "en",
    hCaptchaRequired: false,
    hCaptchaSiteKey: "7492995e-a340-4eb1-b5b5-28f5fa664aae",
    masterEmail: "hey@invoicecash.com",
    social:{
        phone: "0374491600",
        facebook: "https://www.facebook.com/invoicecash/",
        instagram: "https://www.instagram.com/invoicecash/",
        youtube: "https://www.youtube.com/channel/UCNEzh3WjG0yx2zY8XyLAJlw",
        linkedin: "https://www.linkedin.com/company/invoice-cash/"
    }
}

export default environments;
